.login {
    height: 100%;
    margin: 0;

}

.bg {
    /* The image used */

    /* Add the blur effect */

    box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
    background: #999;
    /* 
    background-color: rgb(84, 83, 83);
    filter: blur(8px);
    -webkit-filter: blur(8px); */
    height: 100vh;

}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}

label {
    font-weight: bold;
}

input {
    height: 32;
    margin-bottom: 20px;

    position: relative;
    -webkit-rtl-ordering: logical;
    padding: 1px 2px;
    font-size: 20px;
    text-rendering: auto;
    color: -internal-light-dark(rgba(0, 0, 0, 0.481), white);
    padding-left: 5px;
    line-height: 32px;

}

.main .login .rlogo,
.rlogo {
    font-weight: bold;
    text-align: center;
    font-size: 26px;
    color: #444;
    cursor: pointer;
    height: 40px;
    margin-bottom: 40px;
    overflow: hidden;
}

.loginform {}

.login_btn {
    width: 100%;
    height: 44px;
    background: #20a53a;
    background: linear-gradient(#20a53a, #189d32);
    box-shadow: inset 0 1px 2px #30ad42;
    color: #fff;
    text-shadow: #00851a 0 -1px 0;
    border: 1px solid #20a53a;
    text-align: center;
    font-size: 16px;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
}

.loginContainer {


    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    height: 290px;
    left: 50%;
    margin-left: -220px;
    margin-top: -187.5px;
    padding: 35px 40px 50px;
    position: absolute;
    top: 50%;
    width: 360px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
    background: #999;
}