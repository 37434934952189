.navbar {
    width: 100%;
    height: 50px;
    background-color: white;
    position: sticky;
    top: 0;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    z-index: 999;
}

.navbarWrapper {
    display: flex;
    height: 100%;
    padding: 0px 20px;
    align-items: center;
    justify-content: space-between;
}
.logo{
    font-weight: bold;
    font-size: 30px;
    color: black;
    cursor: pointer;
}
.topRight{
    display: flex;
    align-items: center;
}
.navbarIconContainer{
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
}
.navbarIconBadge{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

/* topLeft
topRight */