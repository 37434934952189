.Maps{

}
.previewImgSmall{
    height: 100%;
    border-radius: 10%;
    cursor: pointer;
}
.delete-btn{
    border: none;
    border-radius: 2px;
    padding: 5px 10px;
    background-color: rgb(255, 89, 0);
    color: white;
    cursor: pointer;
    margin-right: 20px;
}
.download-btn{
    border: none;
    border-radius: 2px;
    padding: 5px 10px;
    background-color: #3b64b0;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}