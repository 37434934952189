.users {
    height: inherit;
}

.userList {
    flex: 4;
    height: calc(100vh - 105px);
    width: 100%;
}
.userListEdit{
    border: none;
    border-radius: 2px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.userListBan{
    border: none;
    border-radius: 2px;
    padding: 5px 10px;
    background-color: rgb(255, 89, 0);
    color: white;
    cursor: pointer;
    margin-right: 20px;
}
.userListUnban{
    border: none;
    border-radius: 2px;
    padding: 5px 10px;
    background-color: #3b64b0;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}