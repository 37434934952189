.usersTopbar {
    width: 100%;
    display: flex;
}

.topbarItem {
    flex: 1;
    display: inherit;
    padding: 15px;
    cursor: pointer;
    /* -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75); */
    justify-content: center;
    align-items: center;
}

.timeTitle {
    font-size: 20px;
}

.topbarItem:hover {
    background-color: rgba(213, 213, 253, 0.316);
}

.topbarItem:active {
    background-color: rgba(213, 213, 253, 0.516);
}

.active {
    background-color: rgba(213, 213, 253, 0.516);
}

.topbarItemActive {
    background-color: rgba(213, 213, 253, 0.516);
}

