.sidebar {
    flex: 1;
    height: calc(100vh - 50px);
    background-color: rgb(250, 250, 255);
    position: sticky;
    top: 50px;
}

.sidebarWrapper {
    padding: 20px;
    color: #555;
}

.sidebarMenu {
    margin-bottom: 10px;
}

.sidebarTitle {
    font-size: 13px;
    color: rgb(199, 198, 198);
}

.sidebarList {
    list-style-type: none;
    padding: 5px;
}

.sidebarListItem {
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    /* border-radius: 5px; */
}

.sidebarListItem:hover{
    background-color: rgba(213, 213, 253, 0.316);
}
.sidebarListItem:active {
    background-color: rgba(213, 213, 253, 0.516);
}