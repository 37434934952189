.container {
    display: flex;
    
}

.Link{
    color: inherit;
    text-decoration: none;
}

.loading{
    display: flex;
    height: calc(100% - 55px);
    align-items: center;
    justify-content: center;
}
.page{
    flex: 5;
}