.User {
    padding: 20px;
}

.userTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userBlockButton {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: rgb(255, 89, 0);

    border-radius: 2px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}
.userContainer {
    display: flex;
    margin-top: 20px;
  }

  .userInfoContainer {
    flex: 1;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
  .userMaps {
    flex: 3;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    margin-left: 20px;
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .userInfoLabel {
    font-weight: 600;
  }
  .userInfoValue{
padding-left: 5px;
  }
  .blocked{
    background-color: #3b64b0;
  }